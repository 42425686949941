<template>
  <div class="container">
    <Header :active="2" />
    <div class="carousel-box">
      <div class="carousel-item">
        <div class="carousel-item-text-box">
          <div class="carousel-item-text-box-title">居家照护</div>
          <div class="carousel-item-text-box-tips">Home health care</div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(23), marginBottom: pxToRem(40) }"
          ></div>
          <div class="carousel-item-text-box-info">
            1CARE为选择居家和社区养老的老年人、家属，以及为居家/社区养老提供服务的机构和护理人员，提供可预见性的解决方案
          </div>
          <img
            class="carousel-item-text-box-btn"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/icon_buy.png"
            alt=""
          />
          <div class="imgCont">
            <el-image
              class="bigImg"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img2.png"
              lazy
            ></el-image>
            <!-- <img class="bigImg" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img2.png" alt=""> -->
            <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_play.png" alt="" class="iconPlay"> -->
            <!-- <p class="playTitle">————  演示视频</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section2 care">
      <div class="title-box">
        <div class="title">精心呵护</div>
        <div class="subtitle">Carefully Care</div>
      </div>
      <div class="swiperCont">
        <div class="swiper1 swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, ind) in swiperList1"
              :key="ind"
              :style="{
                background: 'url(' + item.img + ') no-repeat',
                backgroundSize: 'auto 100%',
              }"
            >
              <div class="swiperBlock"></div>
              <div class="swiperText1">
                <div class="swiperText1-title">
                  <span>{{ item.title }}</span>
                  <div class="swiperText1-title-img"></div>
                </div>
                <p class="swiperText1-tips">{{ item.desc1 }}</p>
                <!-- <p class="swiperText1-tips">{{ item.desc2 }}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section2 protect">
      <div class="title-box">
        <div class="title">健康监测技术的创新者</div>
        <div class="subtitle">Health monitoring technology innovators</div>
      </div>
      <div class="txtCont">
        <img
          class="icon_right"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
          alt=""
        />
        <div class="desc">
          <p>
            1CARE为高端居家/社区养老的护理人员提供专业操作意见，让老人及其亲人安心;为选择居家和社区养老的老年人、家属，以及为居家/社区养老提供服务的机构和护理人员，提供可操作的主动健康、护理建议；
          </p>
          <p>
            1CARE通过多模感知技术收集丰富的数据集来训练深度学习神经网络，以揭示与跌倒、尿路感染、抑郁症、心脑血管疾病或糖尿病风险增加相关的关键信息；1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；
          </p>
          <p>
            基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；
          </p>
        </div>
      </div>
      <div class="swiperCont2">
        <div class="swiperBg">
          <p class="swiperTitle">
            {{ swiperActivedObj.title }}
            <img
              class="iconRight"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
              alt=""
            />
          </p>
          <p class="swiperTitle">{{ swiperActivedObj.subTitle }}</p>
          <div class="swiperLine"></div>
          <p class="swiperDesc">{{ swiperActivedObj.text1 }}</p>
          <p class="swiperDesc">{{ swiperActivedObj.text2 }}</p>
        </div>
        <div class="carouselCont swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, ind) in swiperList2"
              :key="ind"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
        <img
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_prev.png"
          class="carouselPrev"
          alt=""
        />
        <img
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_next.png"
          class="carouselNext"
          alt=""
        />
        <div class="progressCont">
          <el-progress
            :stroke-width="6"
            :percentage="progressObj.percentage"
            :color="'#90c446'"
            :format="format"
          ></el-progress>
          <span>{{ progressObj.page }}/<span class="small">03</span></span>
        </div>
      </div>
    </div>
    <div class="strategic_bg">
      <div class="section2 strategic">
        <div class="title-box">
          <div class="title">全天候的精心呵护</div>
          <div class="subtitle">24/7 Valuable Insigh</div>
        </div>
        <div class="wristbandCont">
          <div class="contLeft">
            <div v-for="(item, ind) in strategicList" :key="ind">
              <div
                v-if="ind < 3"
                :class="item.actived ? 'itemCont active' : 'itemCont'"
              >
                <div class="titleBg" @click="handleShowDetail(ind)">
                  <p class="txt">{{ item.title }}</p>
                  <img
                    class="icon"
                    v-if="item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top_white.png"
                    alt=""
                  />
                  <img
                    class="icon"
                    v-if="!item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top.png"
                    alt=""
                  />
                </div>
                <div class="cont" v-if="item.actived">
                  <ul>
                    <li v-for="(el, i) in item.subTitleList" :key="i">
                      {{ el }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <el-image
            class="wristbandImg"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/wristband.png"
            lazy
          ></el-image>
          <div class="contRight">
            <div v-for="(item, ind) in strategicList" :key="ind">
              <div
                v-if="ind >= 3"
                :class="item.actived ? 'itemCont active' : 'itemCont'"
              >
                <div class="titleBg2" @click="handleShowDetail(ind)">
                  <p class="txt">{{ item.title }}</p>
                  <img
                    class="icon"
                    v-if="item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top_white.png"
                    alt=""
                  />
                  <img
                    class="icon"
                    v-if="!item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top.png"
                    alt=""
                  />
                </div>
                <div class="cont" v-if="item.actived">
                  <ul>
                    <li v-for="(el, i) in item.subTitleList" :key="i">
                      {{ el }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  name: "homeCare",
  data() {
    return {
      carouselHeight: "",
      tabIndex: 0,
      strategicList: [
        {
          title: "居家养老",
          subTitleList: [
            "1CARE帮助家庭护理服务提供者在客户最需要得到护理的领域优先安排员工时间，知用户之所需，急用户之所急，获得用户更多信赖；",
          ],
          index: 0,
          actived: true,
        },
        {
          title: "提高服务水平",
          subTitleList: [
            "依据1CARE提供的科学、客观的健康数据，以此来提高护理计划或监护措施，并以此向用户证明，提高护理服务等级的必要性；",
          ],
          index: 1,
          actived: false,
        },
        {
          title: "精准定位和预警",
          subTitleList: [
            "通过1CARE精确定位，设立警戒区域，当特定人员接近禁区时，设备将发送多模式提醒，并显示其确切位置和历史轨迹；",
          ],
          index: 2,
          actived: false,
        },
        {
          title: "护理延展",
          subTitleList: [
            "对于还没有准备好接受机构养老的老人，1CARE可以提供让家人放心的居家养老监控；对于不能或不愿意搬到养老机构的老人，1CARE可以提供全天侯的监控来补充居家护理服务；",
          ],
          index: 3,
          actived: false,
        },
        {
          title: "让家人安心",
          subTitleList: [
            "家庭护理提供者可以选择与家人分享令人安心的信息，让他们了解亲人的生活，并获得宝贵的健康指导；",
          ],
          index: 4,
          actived: false,
        },
        {
          title: "增加用户粘性",
          subTitleList: [
            "1CARE能够帮助护理人员提前预知可能出现的健康问题，如跌倒风险的增加和尿路感染等，这些问题经常会导致入住率下降；",
          ],
          index: 5,
          actived: false,
        },
      ],
      sectionSixList: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img1.png",
          width: 86,
          height: 80,
          text1: "科学建议",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img2.png",
          width: 86,
          height: 75,
          text1: "机构管理",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img3.png",
          width: 92,
          height: 75,
          text1: "护理人员",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img4.png",
          width: 69,
          height: 75,
          text1: "智能AI",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img5.png",
          width: 90,
          height: 70,
          text1: "褥疮预防",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img6.png",
          width: 89,
          height: 85,
          text1: "睡眠监测",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img7.png",
          width: 85,
          height: 72,
          text1: "呼吸暂停",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img8.png",
          width: 96,
          height: 85,
          text1: "实时定位",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img9.png",
          width: 86,
          height: 86,
          text1: "免钥进入",
          text2: "",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img10.png",
          width: 87,
          height: 83,
          text1: "智慧预测",
          text2: "",
        },
      ],
      swiperList1: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/carefully-img1.png",
          title: "机构管理",
          desc1:
            "新冠病毒持续大流行期间，养老机构面临着运营成本持续增加、入住率持续下降的艰难时期。借助1CARE的接触历史感知技术，养老机构可以确保老人的安全，并籍此获得机构、社区、家属等多方信任；",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/carefully-img2.png",
          title: "护理人员",
          desc1:
            "新冠病毒持续大流行期间，养老机构常处于隔离状态，护理人员必须更加努力地为老人提供细致护理服务，同时还要遵守各种防疫措施，这让她们不堪重负，加剧了人员流失；1CARE接触者感知技术，可在几秒钟内识别出所有密接人员，这可大量减轻工作量；同时，还可通过1CARE应用程序实现双向交流，老人任何的需求可以得到最近人员的及时回应；",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/carefully-img3.png",
          title: "长辈和亲人",
          desc1:
            "旨在保护弱势群体的防疫隔离措施，可能会导致老人身体、精神和情绪的彻底崩溃；隔离期间，我们无法知道他们是如何应对的，这让我们家人非常不安；通过1CARE接触者感知技术，养老机构可以仅隔离高风险人员，这让养老机构、老人和家属安心；此外，借助1CARE平台，家人可以随时获悉他们的健康信息；",
        },
      ],
      swiperList2: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img1.png",
          title: "智慧居家照护",
          subTitle: "提供者",
          desc: "Smart Home Care",
          text1:
            "1CARE正站在医养技术研发的第一线，正努力地为医养事业寻求有效的解决方案；1CARE面临着老人认知下降变化、健康状态变化、员工工作效率以及与通信交流等储多新技术的严峻挑战；",
          text2:
            "1CARE提供及时的可见性和预测性，能够采取主动行动方案来解决日益增长的护理需求，提高员工的工作效率以及根据护理质量提高收入；",
          isactived: true,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img2.png",
          title: "护理人员",
          subTitle: "",
          desc: "Smart Home Care2",
          text1:
            "照顾居家老人是一项有益于家庭和社会的工作，但有时这也是令人十分沮丧的工作；对于非全职的家庭护理人员来说，不可能观察到可能会导致严重健康问题的生活活动的细微变化；",
          text2:
            "1CARE能够监测到可能表明认知问题、慢性病、尿路感染、跌倒风险和心理健康风险等进展的细微变化；",
          isactived: false,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img3.png",
          title: "亲情圈",
          subTitle: "",
          desc: "Smart Home Care3",
          text1:
            "让老人完全融入新的群体，让新朋友成为老人日常生活的一部分，并开心生活，这对决定将老人送入养老院的人来说是压力山大的；亲人们担心是否做出了正确的选择，他们时刻想知道他们所爱的人健康是否受到密切监测、意愿是否都得到了满足、或者是否需要更多帮助；",
          text2:
            "1CARE可为家庭护理服务提供者、家属以及老人分享让人放心的重要信息；",
          isactived: false,
        },
      ],
      swiperActivedObj: {},
      progressObj: {
        percentage: 33,
        page: "01",
      },
    };
  },
  mounted() {
    this.getCarouselHeight();
    window.onresize = () => {
      if (document.body.clientWidth > 1200) {
        this.getCarouselHeight();
      }
    };
    this.swiperInit();
  },
  methods: {
    getCarouselHeight() {
      let domWidth = document.body.clientWidth;
      this.carouselHeight = (domWidth * 1606) / 3840 + "px";
    },
    changeTab(i) {
      this.tabIndex = i;
    },
    swiperInit() {
      new Swiper(".carouselCont", {
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: -100,
        loop: true,
        slideToClickedSlide: true,
        // 如果需要前进后退按钮
        nextButton: ".carouselNext",
        prevButton: ".carouselPrev",
        onSlideChangeEnd: (swiper) => {
          if (swiper.activeIndex % 3 == 0) {
            this.swiperActivedObj = this.swiperList2[0];
            this.progressObj = {
              percentage: 33,
              page: "01",
            };
          } else if (swiper.activeIndex % 3 == 1) {
            this.swiperActivedObj = this.swiperList2[1];
            this.progressObj = {
              percentage: 66,
              page: "02",
            };
          } else {
            this.swiperActivedObj = this.swiperList2[2];
            this.progressObj = {
              percentage: 100,
              page: "03",
            };
          }
        },
      });
    },
    handleShowDetail(ind) {
      this.strategicList.forEach((el) => {
        el.actived = false;
      });
      this.strategicList[ind].actived = true;
    },
    format() {
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
  padding-bottom: 100px;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.carousel-box {
  width: 100%;
  .carousel-item {
    width: 100%;
    height: 850px;
    position: relative;
    background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/banner1.png) no-repeat;
    background-size: 100% 100%;
    .carousel-img {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
    }
    &-text-box {
      position: absolute;
      width: 1200px;
      box-sizing: border-box;
      // padding-left: 30px;
      //   padding-right: 780px;
      // height: 300px;
      // left: 360px;
      top: 185px;
      left: 0;
      right: 0;
      margin: 0 auto;
      &-title {
        font-size: 64px;
        color: #1f3876;
        font-family: "UI-Bold";
        span {
          color: #90c446;
        }
      }
      &-tips {
        font-family: "CN-Light";
        color: #90c446;
        font-size: 31px;
      }
      &-info {
        font-family: "CN-Light";
        line-height: 40px;
        font-size: 21px;
        color: #213763;
        width: 523px;
      }
      &-btn {
        width: 171px;
        height: 56px;
        margin-top: 38px;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.8s;
      }
      &-btn:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
    .imgCont {
      position: absolute;
      width: 577px;
      height: 332px;
      left: 623px;
      top: 0;
      .bigImg {
        width: 577px;
        height: 332px;
        cursor: pointer;
        transition: all 0.8s;
      }
      .bigImg:hover{
        transform: scale(1.02);
      }
      .iconPlay {
        width: 114px;
        height: 72px;
        position: absolute;
        top: 126px;
        left: 251px;
      }
      .playTitle {
        font-size: 18px;
        line-height: 27px;
        color: #fff;
        width: 100%;
        text-align: right;
        position: absolute;
        bottom: 8px;
        right: 89px;
      }
    }
  }
}
.title-box {
  padding-left: 30px;
  .title {
    font-family: "UI-Regular";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.section1 {
  margin-top: 15px;
  &-box {
    width: 1200px;
    height: 197px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 67px;
    position: relative;
    &-text {
      width: 1093px;
      margin: 0 auto;
      font-family: "CN-Light";
      color: #727171;
      font-size: 21px;
      line-height: 36px;
    }
    &-tip {
      width: 1093px;
      margin: 0 auto;
      padding-top: 2px;
      font-family: "CN-Medium";
      font-size: 21px;
      color: #1f3876;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-left: 17px;
      }
    }
    &-tab {
      width: 856px;
      height: 72px;
      background: #ffffff;
      border-radius: 36px 36px 36px 36px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      position: absolute;
      top: -36px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 27px;
      font-family: "CN-Medium";
      color: #1f3876;
      transition: all 0.8s;
      &-item {
        border-radius: 36px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 45px;
        cursor: pointer;
        &-active {
          background: #90c446;
          color: #ffffff;
        }
      }
    }
  }
}
.section2 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 165px;
  .txtCont {
    .title {
      font-size: 32px;
      color: #1f3876;
      line-height: 46px;
      font-weight: bold;
      img {
        width: 24px;
        height: 24px;
      }
      .icon_left {
        margin-right: 15px;
      }
      .icon_right {
        margin-left: 15px;
      }
    }
    .desc {
      margin-top: 5px;
      font-family: "CN-Light";
      color: #727171;
      font-size: 21px;
      line-height: 32px;
      text-indent: 44px;
      text-align: justify;
    }
  }
}
.life {
  .nursingStaff,
  .relatives {
    margin-top: 90px;
    display: flex;
    align-items: flex-end;
    .bigImg {
      width: 472px;
      height: 272px;
      margin-right: 86px;
    }
  }
  .relatives {
    .bigImg {
      width: 747px;
      height: 430px;
      margin-left: 88px;
      margin-right: 0;
    }
    .txtCont {
      width: 367px;
      .title {
        text-align: right;
      }
    }
  }
}
.care,
.protect {
  .txtCont {
    display: flex;
    margin: 49px 0 0 30px;
    .icon_right {
      width: 24px;
      height: 24px;
      margin-right: 30px;
    }
    .desc {
      width: 1010px;
      font-size: 22px;
      line-height: 34px;
      color: #727171;
      text-indent: 44px;
      text-align: justify;
    }
  }
  .swiperCont {
    position: relative;
    margin-top: 146px;
    .swiper1 {
      width: 1200px;
      height: 762px;
      .swiper-slide {
        width: 370px;
        height: 697px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin-right: 48px;
        .swiperBlock {
          width: 100%;
          height: 20px;
          background: #90c446;
          position: absolute;
          bottom: 0;
          z-index: 1;
        }
        .swiperText1 {
          cursor: pointer;
          position: absolute;
          bottom: -445px;
          width: 100%;
          background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-img-bg.png")
            no-repeat;
          background-size: 100% 100%;
          height: 582px;
          padding: 40px 27px 0 51px;
          box-sizing: border-box;
          transition: all 0.8s;
          &-title {
            font-family: "CN-Medium";
            font-size: 31px;
            color: #1f3876;
            display: flex;
            align-items: center;
            span {
              width: 220px;
            }
            &-img {
              width: 24px;
              height: 23px;
              margin-left: 18px;
              background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-up.png") no-repeat;
              background-size: 100% 100%;
              transition: all 0.8s;
            }
          }
          &-tips {
            font-family: "CN-Light";
            color: #727171;
            font-size: 21px;
            margin-top: 25px;
            text-align: justify;
            position: relative;
          }
          &-tips::before {
            width: 11px;
            height: 11px;
            content: "";
            background: #727171;
            border-radius: 50%;
            position: absolute;
            left: -23px;
            top: 12px;
          }
        }
        .swiperText1:hover {
          bottom: 0;
        }
        .swiperText1:hover .swiperText1-title-img {
          background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .swiper-slide:last-child {
        margin-right: 0;
      }
    }
    .swiper-button-prev {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_prev.png");
      width: 62px;
      height: 62px;
      background-size: auto 100%;
      z-index: 10;
    }
    .swiper-button-next {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_next.png");
      width: 62px;
      height: 62px;
      background-size: auto 100%;
      z-index: 10;
    }
  }
}
.protect {
  .swiperCont2 {
    height: 838px;
    position: relative;
    .swiperBg {
      position: absolute;
      left: 0;
      top: 108px;
      width: 609px;
      height: 609px;
      background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_bg.png);
      background-size: 100%;
      padding: 66px 0 0 45px;
      box-sizing: border-box;
      .swiperTitle {
        font-family: "UI-Medium";
        font-size: 37px;
        line-height: 45px;
        color: #1f3876;
        .iconRight {
          width: 24px;
          height: 24px;
          margin-left: 40px;
        }
      }
      .swiperLine {
        width: 37px;
        height: 4px;
        background: #1f3876;
        margin: 20px 0;
      }
      .swiperDesc {
        width: 317px;
        font-family: "UI-Light";
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-indent: 42px;
        text-align: justify;
      }
    }
    .carouselCont {
      width: 773px;
      height: 493px;
      position: absolute;
      left: 400px;
      top: 166px;
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }
      .swiper-slide {
        cursor: pointer;
        width: 306px;
        height: 415px;
        text-align: center;
        font-size: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      .swiper-slide img {
        width: 100%;
        height: 100%;
      }
      .swiper-slide.swiper-slide-duplicate{
        z-index: 2;
      }
      .swiper-slide.swiper-slide-active {
        width: 362px !important;
        height: 493px;
        z-index: 5;
      }
      .swiper-slide.swiper-slide-next {
        z-index: 4;
      }
      .swiper-slide.swiper-slide-duplicate-prev {
        z-index: 3;
      }
      .swiper-slide.swiper-slide-duplicate-active{
        z-index: 1;
      }
    }
    .carouselPrev,
    .carouselNext {
      width: 62px;
      height: 62px;
      position: absolute;
      left: -93px;
      top: 382px;
      cursor: pointer;
    }
    .carouselNext {
      left: 1200px;
      z-index: 10;
    }
    .progressCont {
      position: absolute;
      top: 800px;
      width: 800px;
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      /deep/ .el-progress {
        width: 507px;
        padding-right: 0;
      }
      /deep/ .el-progress-bar {
        padding-right: 0;
      }
      /deep/ .el-progress-bar__outer {
        background: #fff;
      }
      span {
        margin-left: 40px;
        color: #90c446;
        font-family: "UI-Medium";
        font-weight: bold;
        font-size: 24px;
      }
      .small {
        font-size: 16px;
        margin-left: 0;
      }
    }
  }
}
.strategic_bg {
  width: 100%;
  height: 1205px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/bg_img1.png") no-repeat;
  background-size: 100% 100%;
  .strategic {
    padding-top: 270px;
    .title {
      font-weight: bold;
    }
    .subtitle {
      color: #fff;
    }
    .wristbandCont {
      display: flex;
      margin-top: 120px;
      .contLeft,
      .contRight {
        width: 423px;
        margin-right: 80px;
        .itemCont {
          position: relative;
          top: 0;
          height: 85px;
          margin-bottom: 50px;
          .titleBg,
          .titleBg2 {
            cursor: pointer;
            position: absolute;
            top: 0;
            z-index: 2;
            width: 423px;
            height: 85px;
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_white1.png);
            background-size: 100% 100%;
            .txt {
              font-size: 30px;
              color: #1f3876;
              line-height: 68px;
              display: inline-block;
              text-align: center;
              width: 375px;
            }
            .icon {
              width: 24px;
              height: 24px;
            }
          }
          .titleBg2 {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_white2.png);
            background-size: 100% 100%;
          }
          .cont {
            position: absolute;
            top: 43px;
            z-index: 0;
            background: #fff;
            border-radius: 0 0 40px 40px;
            width: 423px;
            padding: 40px;
            box-sizing: border-box;
            font-size: 25px;
            line-height: 36px;
            color: #1f3876;
            li {
              list-style-type: none;
            }
          }
        }
        .itemCont.active {
          height: 350px;
          .titleBg {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_green.png);
            background-size: 100% 100%;
            .txt {
              color: #fff;
            }
          }
          .titleBg2 {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_green1.png);
            background-size: 100% 100%;
            .txt {
              color: #fff;
            }
          }
        }
      }
      .wristbandImg {
        width: 208px;
        height: 292px;
        transition: all 0.8s;
        cursor: pointer;
      }
      .wristbandImg:hover {
        transform: scale(1.02);
      }
      .contRight {
        width: 423px;
        margin-left: 68px;
        margin-right: 0;
      }
    }
  }
}
.section6_bg {
  width: 100%;
  height: 1160px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_bg.png") no-repeat;
  background-size: auto 10%;
  background-position: 100% 24%;
}
.section6 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #90c446;
      margin-top: 5px;
    }
  }
  &-box {
    width: 1200px;
    height: 535px;
    background: #ffffff;
    border-radius: 25px 25px 22px 25px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin-top: 120px;
    // padding: 84px 0;
    padding-bottom: 80px;
    box-sizing: border-box;
    &-row {
      display: flex;
      align-items: center;
      height: 50%;
      flex-wrap: wrap;
      &-item {
        width: 20%;
        height: 100%;
        color: #1f3876;
        text-align: center;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding-top: 84px;
        box-sizing: border-box;
        &-img {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text1 {
          font-family: "CN-Medium";
          font-size: 21px;
          margin-top: 25px;
          // margin-bottom: 0px;
        }
        &-text2 {
          font-family: "CN-Medium";
          font-size: 21px;
        }
      }
    }
  }
}
.section7_bg {
  width: 100%;
  height: 1190px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7_bg.png") no-repeat;
  background-size: auto 100%;
}
.section7 {
  width: 1200px;
  margin: 0 auto;
  &-header-box {
    margin-left: 30px;
    padding-top: 150px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #fff;
      margin-top: 5px;
    }
    .horizontal-line {
      margin: 30px 0 118px 0;
      background: #fff;
    }
  }
  &-box {
    width: 1200px;
    height: 657px;
    display: flex;
    align-items: flex-end;
    &-left {
      width: 463px;
      margin-right: 80px;
      &-title {
        font-size: 32px;
        line-height: 47px;
        color: #1f3876;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 30px;
      }
      &-text {
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-align: justify;
      }
      &-img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }
    }
    &-right {
      width: 657px;
      height: 657px;
      background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7-bg-cricle.png);
      background-size: auto 100%;
      padding-top: 163px;
      box-sizing: border-box;
      &-img {
        width: 627px;
        height: 352px;
      }
      &-start {
        position: relative;
        top: -205px;
        left: 276px;
        width: 75px;
        height: 49px;
      }
      &-box {
        display: flex;
        &-img {
          width: 64px;
          height: 64px;
          margin-left: 90px;
        }
        &-text {
          font-size: 21px;
          line-height: 32px;
          color: #1f3876;
          text-align: justify;
          width: 382px;
          margin-left: 10px;
          margin-top: 18px;
        }
      }
    }
  }
}
.section8 {
  width: 1200px;
  margin: 0 auto 143px;
  &-header-box {
    margin-left: 30px;
    padding-top: 150px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #90c446;
      margin-top: 5px;
    }
    .horizontal-line {
      margin: 30px 0 118px 0;
    }
  }
  &-box {
    width: 1200px;
    &-img {
      width: 1200px;
    }
    &-text {
      display: flex;
      margin-top: 84px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      p {
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-indent: 42px;
        width: 1051px;
      }
    }
  }
}
</style>
